import { createSlice } from "@reduxjs/toolkit";

import { ApiKey } from "apis/apiKey";

import { Action, State } from "./store";

export const apiKeysSlice = createSlice({
  name: "apiKeys",
  initialState: {
    value: undefined as ApiKey[] | undefined,
  },
  reducers: {
    addApiKeys: (state, action: Action<ApiKey[] | undefined>) => {
      state.value = action.payload;
    },
    addApiKey: (state, action: Action<ApiKey>) => {
      state.value = [action.payload, ...(state.value || [])];
    },
    deleteApiKey: (state, action: Action<string>) => {
      state.value = state.value?.filter((key) => key.token != action.payload);
    },
  },
});

export const { addApiKeys, addApiKey, deleteApiKey } = apiKeysSlice.actions;

export const selectApiKeys = (state: State): ApiKey[] | undefined => {
  return state.apiKeys.value;
};

export default apiKeysSlice.reducer;
