import { AuthChangeEvent, AuthError, Session } from "@supabase/supabase-js";
import { logger } from "interfaces/logger";
import { updateAccountProfile } from "store/accountProfileSlice";
import store from "store/store";

import { supabase } from "apis/supabaseClient";

import { getEmailVerifiedUrl, getExternalPath } from "utils/urls";

export const signInWithPassword = async (email: string, password: string) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  return { data, error };
};

export const signInWithGoogle = async (
  redirectTo?: string
): Promise<{
  error: AuthError | null;
}> => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider: "google",

    // We specify the redirect URL if we don't specify it, it will default to whatever is set in Supabase
    options: {
      redirectTo,
      queryParams: {
        prompt: "select_account",
      },
    },
  });

  if (error) {
    logger(error.message, "error");
  }
  return { error };
};

export const signInWithOtp = async (email: string, redirectUrl?: string) => {
  const { data, error } = await supabase.auth.signInWithOtp({
    email: email,
    options: { emailRedirectTo: redirectUrl },
  });

  if (error) {
    logger(error.message, "error");
    // throw new Error(error.message);
  }

  return { data, error };
};

export const signUp = async ({
  email,
  password,
  firstName,
  lastName,
  currentPath,
  onSuccessfulAuthRedirect,
}: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  currentPath?: string;
  onSuccessfulAuthRedirect?: string;
}) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: {
        first_name: firstName,
        last_name: lastName,
      },
      emailRedirectTo: getExternalPath(
        getEmailVerifiedUrl({ currentPath, onSuccessfulAuthRedirect })
      ),
    },
  });

  if (error?.message.includes("For security purposes, you can only request")) {
    return {
      data,
      error:
        "Please check your email and junk folder to login. You can only request a new email after 60 seconds.",
    };
  }

  // in staging, we don't verify primary emails
  // Supabase returns a nice error
  if (error?.message === "User already registered")
    return { data, error: "Please sign in with your existing account" };

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  // in production, we verify primary emails
  // supabase returns a user object with no identities if the user exists
  if (data?.user?.identities?.length === 0) {
    return { data, error: "Please sign in with your existing account" };
  }

  return { data, error };
};

export const signOut = async (): Promise<{ error: AuthError | null }> => {
  const { error } = await supabase.auth.signOut();

  if (error) {
    logger(error.message, "error");
  }

  store.dispatch(updateAccountProfile(undefined));

  return { error };
};

export const getSession = async () => {
  const { data, error } = await supabase.auth.getSession();

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.session;
};

export const onAuthStateChange = (
  callback: (event: AuthChangeEvent, session: Session | null) => void
) => {
  const { data } = supabase.auth.onAuthStateChange(callback);

  return data.subscription;
};

export const updatePassword = async (password: string) => {
  const { data, error } = await supabase.auth.updateUser({
    password,
  });

  if (error) {
    logger(error.message, "error");
  }

  return { data, error };
};

export const anonymousSignIn = async (): Promise<string | undefined> => {
  const { data, error } = await supabase.auth.signInAnonymously();
  const userId = data?.user?.id;
  if (!userId) {
    logger(
      error?.message || "something went wrong when signing in anonymously",
      "error"
    );
  }
  return userId;
};
