import React, { useContext, useEffect, useMemo } from "react";

import { css } from "@emotion/react";

import { useRouter } from "next/router";

import { PlanSuccessModal } from "components/recruit/shared/PlanSuccessModal";
import { UpgradePlanButton } from "components/recruit/shared/UpgradePlanButton";
import { Authenticated } from "components/shared/library/Authenticated";
import { LoadingFullScreen } from "components/shared/library/LoadingFullScreen";

import { useIsOnboardingComplete } from "apis/onboardings";
import { updateRecruitingPlan } from "apis/recruit";
import { RecruitPlan, useRecruitPlanRealtime } from "apis/recruitingTeam";

import { getRecruitJoinTeamBaseUrl, getRecruitUrl } from "utils/urls";

import { gradient } from "constants/colors";

import { useAccount } from "./AccountContext";

type Context = { plan: RecruitPlan };

export const RecruitPlanContext = React.createContext<Context>({} as Context);

const style = {
  background: css({
    background: gradient.background,
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }),
};

type Props = {
  children: React.ReactNode;
};
export const PlanGatingProvider = ({ children }: Props) => {
  const router = useRouter();
  const { showPlanSuccessModal } = router.query;

  const { accountProfile } = useAccount(false);
  const { plan, isLoaded } = useRecruitPlanRealtime();
  const { isOnboardedToRecruit, isLoaded: isOnboardingLoaded } =
    useIsOnboardingComplete();

  const isRecruitRoute = router.pathname.startsWith(getRecruitUrl());

  // if user has no plan, on the recruit route and never onboarded to recruit
  // we create a free trial for them
  const shouldCreateFreeTrialPlan = useMemo(
    () =>
      accountProfile?.id &&
      isRecruitRoute &&
      isLoaded &&
      !plan &&
      isOnboardingLoaded &&
      !isOnboardedToRecruit,
    [
      accountProfile?.id,
      isRecruitRoute,
      isLoaded,
      plan,
      isOnboardingLoaded,
      isOnboardedToRecruit,
    ]
  );

  useEffect(() => {
    if (shouldCreateFreeTrialPlan) {
      updateRecruitingPlan({
        plan: "Starter",
        hasFreeTrial: true,
      });
    }
  }, [shouldCreateFreeTrialPlan]);

  // for unauthed users or pages other than recruit we don't need to gate
  if (!accountProfile?.id || !isRecruitRoute) return <>{children}</>;
  // for join-team route we don't need to gate, user should not need a plan to join a team
  if (router.pathname.includes(getRecruitJoinTeamBaseUrl()))
    return <>{children}</>;

  if (shouldCreateFreeTrialPlan)
    return <LoadingFullScreen text="Creating your account" />;

  if (!isLoaded) return <LoadingFullScreen />;
  if (!plan)
    return (
      <div css={style.background}>
        <UpgradePlanButton currentPlan={null} showButton={false} />
      </div>
    );

  return (
    <RecruitPlanContext.Provider value={{ plan }}>
      {children}
      {plan.planName !== "Enterprise" && showPlanSuccessModal === "true" && (
        <Authenticated>
          <PlanSuccessModal plan={plan} />
        </Authenticated>
      )}
    </RecruitPlanContext.Provider>
  );
};

export const useRecruitPlanContext = () => {
  return useContext(RecruitPlanContext);
};
