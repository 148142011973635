import { createSlice } from "@reduxjs/toolkit";

import { AccountProfile } from "apis/accountProfile";
import { Org } from "apis/orgs";

import { Action, State } from "./store";

export const accountProfileSlice = createSlice({
  name: "accountProfile",
  initialState: {
    accountProfile: {} as AccountProfile | undefined,
    isOnboarded: undefined as { pro: boolean; recruit: boolean } | undefined,
  },
  reducers: {
    updateAccountProfile: (
      state,
      action: Action<AccountProfile | undefined>
    ) => {
      state.accountProfile = action.payload;
    },
    updateActiveOrgId: (state, action: Action<string>) => {
      if (state.accountProfile && Object.keys(state.accountProfile).length) {
        state.accountProfile.activeOrgId = action.payload;
      }
    },
    updateOrg: (
      state,
      action: Action<Pick<Org, "id" | "name" | "photoUrl" | "isVerified">>
    ) => {
      const { id, ...updatedData } = action.payload;
      if (state.accountProfile) {
        state.accountProfile.orgs = state.accountProfile.orgs.map((org) =>
          org.id === id ? { ...org, ...updatedData } : org
        );
      }
    },
    addIsOnboarded: (
      state,
      action: Action<{ pro: boolean; recruit: boolean }>
    ) => {
      state.isOnboarded = action.payload;
    },
    updateIsOnboarded: (
      state,
      action: Action<Partial<{ pro: boolean; recruit: boolean }>>
    ) => {
      if (!state.isOnboarded) return;
      if (action.payload.pro) state.isOnboarded.pro = action.payload.pro;
      if (action.payload.recruit)
        state.isOnboarded.recruit = action.payload.recruit;
    },
  },
});

export const {
  updateAccountProfile,
  updateActiveOrgId,
  updateOrg,
  addIsOnboarded,
  updateIsOnboarded,
} = accountProfileSlice.actions;

export const selectAccountProfile = (state: State) => {
  if (
    !state.accountProfile.accountProfile ||
    !Object.keys(state.accountProfile.accountProfile).length
  )
    return undefined;
  return {
    ...state.accountProfile.accountProfile,
    activeOrg: state.accountProfile.accountProfile.orgs?.find(
      (org) => org.id === state.accountProfile.accountProfile.activeOrgId
    ),
  };
};

export const selectIsOnboarded = (state: State) => {
  return state.accountProfile.isOnboarded;
};

export default accountProfileSlice.reducer;
