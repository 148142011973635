import { AlertCircleIcon } from "Icons/AlertCircle";
import { CheckColorIcon } from "Icons/CheckColor";

import { css } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

import { AlertTextType, AlertType } from "contexts/alertContext";

import { down } from "utils/mediaQueryStrings";

import { red, uiGray, yellow } from "constants/colors";

import { Portal } from "./Portal";
import { Typography } from "./Typography";

const style = {
  alert: css({
    position: "fixed",
    zIndex: 1051, // Antd's dropdown has 1050
    left: "50%",
    top: 48,
    transform: "translate(-50%, 0px)",
    border: "3px solid #FFFFFF",
    display: "flex",
    width: "fit-content",
    padding: "14px 24px 14px 24px",
    borderRadius: 32,
    [down("mobile")]: {
      width: "-webkit-fill-available",
      padding: "16px",
    },
    // animate
    animation: "fade-in 0.3s ease-in-out",
    "@keyframes fade-in": {
      "0%": {
        opacity: 0,
        transform: "translate(-50%, -10px)",
      },
      "100%": {
        opacity: 1,
        transform: "translate(-50%, 0px)",
      },
    },
  }),
  success: css({
    background:
      "radial-gradient(69.12% 69.12% at 50% -7.35%, rgba(4, 194, 80, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
    boxShadow: "0px 2px 24px #E4F4E6",
  }),
  warning: css({
    background:
      "radial-gradient(69.12% 69.12% at 50% -7.35%, rgba(230, 199, 32, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
    boxShadow: "0px 2px 24px rgba(245, 234, 198, 0.3)",
  }),
  error: css({
    background:
      "radial-gradient(69.12% 69.12% at 50% -7.35%, rgba(219, 56, 62, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
    boxShadow: "0px 2px 24px #FCF3F3",
  }),
  separator: css({
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridGap: 8,
  }),
};

type Props = {
  alertType: AlertType;
  alertText: AlertTextType;
};

const getIcon = (alertType: AlertType): EmotionJSX.Element => {
  switch (alertType) {
    case "success":
      return <CheckColorIcon />;
    case "error":
      return <AlertCircleIcon width={18} height={18} stroke={red[600]} />;
    case "warning":
      return <AlertCircleIcon width={18} height={18} stroke={yellow[600]} />;
  }
};

export const Alert = ({ alertType, alertText }: Props) => {
  return (
    <Portal>
      <div css={[style.alert, style[alertType]]}>
        <div css={style.separator}>
          {getIcon(alertType)}
          <Typography tag="p" size="XS" weight="medium" color={uiGray[80]}>
            {alertText}
          </Typography>
        </div>
      </div>
    </Portal>
  );
};
