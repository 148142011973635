import { createSlice } from "@reduxjs/toolkit";

import { Job, JobInteractionEvent, UserJobOverview, UserJobs } from "apis/jobs";

import { Action, State } from "./store";

const map: Record<JobInteractionEvent, keyof UserJobs> = {
  save: "saved",
  view: "viewed",
  apply: "applied",
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState: {
    value: {} as UserJobOverview & UserJobs,
    pageNumber: { save: 0, view: 0, apply: 0 } as {
      save: number;
      view: number;
      apply: number;
    },
  },
  reducers: {
    increasePageNumber: (state, action: Action<JobInteractionEvent>) => {
      state.pageNumber[action.payload] = state.pageNumber[action.payload] + 1;
    },
    addPaginatedUserJobs: (
      state,
      action: Action<{
        jobInteraction: JobInteractionEvent;
        jobs: Job[];
      }>
    ) => {
      const jobType = map[action.payload.jobInteraction];
      state.value = {
        ...state.value,
        [jobType]: [...(state.value[jobType] || []), ...action.payload.jobs],
      };
    },
    addUserJobOverview: (state, action: Action<UserJobOverview>) => {
      state.value = {
        ...state.value,
        ...action.payload,
      };
    },
    addJob: (
      state,
      action: Action<{ job: Job; jobInteraction: JobInteractionEvent }>
    ) => {
      const jobType = map[action.payload.jobInteraction];
      // check if this was added already (via optimistic updates) and early return if so
      if (
        state.value[jobType].find(
          (job) => job.jobId === action.payload.job.jobId
        )
      )
        return;
      state.value = {
        ...state.value,
        [action.payload.jobInteraction]:
          state.value[action.payload.jobInteraction] + 1,
        [jobType]: [action.payload.job, ...state.value[jobType]],
      };
    },
    deleteJobInteraction: (
      state,
      action: Action<{
        jobId: string;
        jobInteractionEvent: JobInteractionEvent;
      }>
    ) => {
      const jobType = map[action.payload.jobInteractionEvent];
      state.value = {
        ...state.value,
        [action.payload.jobInteractionEvent]:
          state.value[action.payload.jobInteractionEvent] - 1,
        [jobType]: state.value[jobType].filter(
          (job) => job.jobId !== action.payload.jobId
        ),
      };
    },
    copyJobInteraction: (
      state,
      action: Action<{
        jobId: string;
        originalJobInteractionEvent: JobInteractionEvent;
        newJobInteractionEvent: JobInteractionEvent;
      }>
    ) => {
      const originalJobType = map[action.payload.originalJobInteractionEvent];
      const movedJob = state.value[originalJobType].find(
        (job) => job.jobId === action.payload.jobId
      );
      if (!movedJob) return;
      const copiedJob = { ...movedJob };
      copiedJob.eventType = action.payload.newJobInteractionEvent;
      const newJobType = map[action.payload.newJobInteractionEvent];

      state.value = {
        ...state.value,
        [action.payload.newJobInteractionEvent]:
          state.value[action.payload.newJobInteractionEvent] + 1,
        [newJobType]: [copiedJob, ...state.value[newJobType]],
      };
    },
    revertCopyJobInteraction: (
      state,
      action: Action<{
        jobId: string;
        newJobInteractionEvent: JobInteractionEvent;
      }>
    ) => {
      const newJobType = map[action.payload.newJobInteractionEvent];
      // Remove the job that was added last
      // (there can be 2 jobs with same job Id: the "real" one and one added by the optimistic update)
      const optimisticIdx = state.value[newJobType].findIndex(
        (job) => job.jobId === action.payload.jobId
      );
      if (optimisticIdx === -1) return;
      const newUserJobsByType = [...state.value[newJobType]];
      newUserJobsByType.splice(optimisticIdx, 1);
      state.value = {
        ...state.value,
        [action.payload.newJobInteractionEvent]:
          state.value[action.payload.newJobInteractionEvent] - 1,
        [newJobType]: newUserJobsByType,
      };
    },
  },
});

export const {
  addPaginatedUserJobs,
  addUserJobOverview,
  addJob,
  increasePageNumber,
  deleteJobInteraction,
  copyJobInteraction,
  revertCopyJobInteraction,
} = jobsSlice.actions;

export const selectJobsByEventType =
  (type: JobInteractionEvent) => (state: State) => {
    return state.jobs.value[map[type]] || [];
  };

export const selectJobsPagenumber =
  (type: JobInteractionEvent) => (state: State) => {
    return state.jobs.pageNumber[type];
  };

export const selectUserJobOverviewByEventType =
  (type: JobInteractionEvent) => (state: State) => {
    return state.jobs.value[type] || 0;
  };

export default jobsSlice.reducer;
