import { CheckCircleIcon } from "Icons/CheckCircle";

import { css } from "@emotion/react";

import { Typography } from "components/shared/library/Typography";

import { purple } from "constants/colors";

const style = {
  bullet: css({
    display: "flex",
    gap: 8,
  }),
  content: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
};

export const RecruitFeatureList = ({ features }: { features: string[] }) => (
  <div css={style.content}>
    {features.map((feature) => (
      <div css={style.bullet} key={feature}>
        <CheckCircleIcon width={16} height={16} stroke={purple[600]} />
        <Typography size="XXS">{feature}</Typography>
      </div>
    ))}
  </div>
);
