import React, { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { trackEvent } from "interfaces/tracker";
import { useRouter } from "next/router";
import { selectAccountProfile } from "store/accountProfileSlice";

import { LoadingFullScreen } from "components/shared/library/LoadingFullScreen";

import { AccountProfile, useAccountProfileRealtime } from "apis/accountProfile";
import { findExistingAccountByEmail } from "apis/addresses";
import { signOut } from "apis/authentication";
import { useIsOnboardingComplete } from "apis/onboardings";
import { getSysAdminRoles } from "apis/sysAdmin";
import { useUserSignupStatusRealtime } from "apis/user";

import {
  getEmailVerifiedUrl,
  getInterviewUrl,
  getOnboardingUrl,
  getPaymentConfirmedUrl,
  getRecruitJoinTeamBaseUrl,
  getRecruitUrl,
  getSignInUrl,
  getSignOutUrl,
  getSignUpUrl,
} from "utils/urls";

import { useIsAuthGated } from "./AuthGatedContext";
import { useAlert } from "./alertContext";
import { useAuth } from "./authContext";

type Context = { accountProfile: AccountProfile };

export const AccountContext = React.createContext<Context>({} as Context);

type Props = {
  children: React.ReactNode;
};
export const AccountProvider = ({ children }: Props) => {
  const router = useRouter();
  const { user } = useAuth();

  const accountProfile = useSelector(selectAccountProfile);
  useAccountProfileRealtime(user);
  const { isOnboarded, isLoaded: isOnboardingLoaded } = useIsOnboardingComplete(
    user?.id
  );

  const { signupStatus } = useUserSignupStatusRealtime(user);

  const { createAlert } = useAlert();

  useEffect(() => {
    const get = async () => {
      if (!user) return;
      if (signupStatus === "addressAlreadyInUse" && user.email) {
        const existingAccount = await findExistingAccountByEmail(
          user.email,
          user.id
        );

        if (existingAccount) {
          // track this event in mixpanel to see how often this happens
          trackEvent("existing_account", {
            invalidUserId: user.id,
            existingAccount: existingAccount,
          });

          // sign the user out from the current session
          signOut();

          // if email provider, redirect to sign in page with email pre-filled
          if (existingAccount.loginProvider == "email") {
            router.push(
              getSignInUrl({
                currentPath: router.pathname,
                email: existingAccount.existingUserEmailLogin,
              })
            );

            createAlert(
              "warning",
              `Please sign in with Email and Password - ${existingAccount.existingUserEmailLogin}.`
            );
          }

          // if google provider, redirect and just show the alert
          if (existingAccount.loginProvider == "google") {
            router.push(getSignInUrl({ currentPath: router.asPath }));

            createAlert(
              "warning",
              `Please sign in with Google - ${existingAccount.existingUserEmailLogin}.`
            );
          }
        }
      }
    };

    if (user) get();
  }, [signupStatus, user, router, createAlert]);

  useEffect(() => {
    getSysAdminRoles();
  }, [user?.id]);

  const shouldRedirectToOnboarding = useMemo(() => {
    // after successful signup, logged in user will be redirected to onboarding, it should not redirect a soon as user logs in, in case there is a referral to save -> https://www.notion.so/ribbon-awards/Referral-feature-8de6965468cd43e3bf6c76523ea344a7
    const excludedPaths = [
      getOnboardingUrl({ currentPath: router.pathname }),
      getSignOutUrl(),
      getSignUpUrl({}),
      getEmailVerifiedUrl({}),
      getEmailVerifiedUrl({ currentPath: getRecruitUrl() }),
      getEmailVerifiedUrl({ currentPath: getInterviewUrl() }),
      getInterviewUrl(),
      getPaymentConfirmedUrl(),
      getRecruitJoinTeamBaseUrl(), // skip onboarding when joining a team
    ];
    return (
      !excludedPaths.includes(router.pathname) &&
      !router.asPath.includes(getInterviewUrl()) &&
      user &&
      !user.is_anonymous &&
      isOnboardingLoaded &&
      !isOnboarded
    );
  }, [router, user, isOnboardingLoaded, isOnboarded]);

  useEffect(() => {
    if (shouldRedirectToOnboarding) {
      router.push(
        getOnboardingUrl({
          currentPath: router.asPath,
        })
      );
    }
  }, [shouldRedirectToOnboarding, router]);

  const value = {
    accountProfile: accountProfile as AccountProfile, // we can cast this as AccountProfile since we return the loading component as long as this is undefined
  };

  if (shouldRedirectToOnboarding) return <LoadingFullScreen />;

  // if unauthed return the chidlren as is, no need to wait for things to be loaded
  if (!user || user.is_anonymous) return <>{children}</>;

  const loaded =
    accountProfile && signupStatus === "complete" && isOnboardingLoaded;

  // otherwise return the children wrapper in the provider, or a loader if not ready
  return (
    <AccountContext.Provider value={value}>
      {loaded ? children : <LoadingFullScreen />}
    </AccountContext.Provider>
  );
};

type ReturnType<T> = T extends true
  ? { accountProfile: AccountProfile }
  : { accountProfile?: AccountProfile };

export const useAccount = <T extends boolean>(
  isStrictlyAuthed: T
): ReturnType<T> => {
  const { isAuthGated } = useIsAuthGated();

  if (!isAuthGated && isStrictlyAuthed) {
    throw new Error(
      "Hook useAccount is used in a component where the user might not be authenticated, either wrap the component or a parent in the Authenticated hook, or pass the isStrictlyAuthed param as false"
    );
  }
  return useContext(AccountContext);
};
