import { LinkExternal02 } from "Icons/LinkExternal02";
import { keyBy } from "lodash";

import { useCallback, useMemo, useState } from "react";

import { css } from "@emotion/react";

import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useRouter } from "next/router";

import { Disclaimer } from "components/shared/library/Disclaimer";
import { Dropdown } from "components/shared/library/Dropdown";
import { Modal } from "components/shared/library/Modal";

import { useAlert } from "contexts/alertContext";

import { updateRecruitingPlan } from "apis/recruit";
import {
  RecruitPlan,
  RecruitPlanName,
  useRecruitCatalogPrices,
  useRecruiterTeamMembers,
} from "apis/recruitingTeam";

import { formatAmountToDollars } from "utils/earnings";
import { useWaitForPlanUpdate } from "utils/recruit";
import { getExternalPath, getExternalPricingUrl } from "utils/urls";

import { RecruitPlanDetail } from "./RecruitPlanDetail";

type SelectablePlan = Exclude<RecruitPlanName, "Enterprise">;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccessfullUpdate: () => void;
  defaultPlanName?: SelectablePlan;
  currentPlan: RecruitPlan | null;
};

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  payment: css({ minHeight: 276 }),
};

export const SelectPlanModal = ({
  onClose,
  isOpen,
  onSuccessfullUpdate,
  defaultPlanName,
  currentPlan,
}: Props) => {
  const currentPlanName = (currentPlan?.planName || undefined) as
    | SelectablePlan
    | undefined;

  // if we supply a defaultPlanName, we should use that as the selected plan
  // otherwise, we should use the current plan if it's not a trial
  // otherwise, we should default to "Growth"
  const [selectedPlan, setSelectedPlan] = useState<SelectablePlan>(
    defaultPlanName ||
      (!!currentPlan?.hasPaymentMethod && currentPlanName) ||
      "Growth"
  );
  const [step, setStep] = useState<"select" | "payment">("select");
  const stripe = useStripe();
  const elements = useElements();
  const { createAlert } = useAlert();
  const router = useRouter();
  const { prices, isLoaded: isPricesLoaded } = useRecruitCatalogPrices();
  const pricesByPlan = useMemo(() => keyBy(prices, "planName"), [prices]);
  const waitForPlanUpdate = useWaitForPlanUpdate({
    currentPlanName,
    selectedPlan,
  });

  const { members, isLoaded: isMembersLoaded } = useRecruiterTeamMembers();
  const tooManyMembers =
    isPricesLoaded &&
    pricesByPlan[selectedPlan].teamSeats < (members?.teamMembers?.length || 0);

  const plans = useMemo(
    () =>
      !isPricesLoaded
        ? []
        : ["Starter", "Growth", "Business"].map((plan) => ({
            name: plan,
            label: `${plan} plan - ${formatAmountToDollars(
              pricesByPlan[plan].planUnitAmountDecimal
            )}/${pricesByPlan[plan].planRecurringInterval} ${
              currentPlanName === plan && currentPlan?.hasPaymentMethod
                ? "(Current)"
                : ""
            }`,
          })),
    [
      isPricesLoaded,
      pricesByPlan,
      currentPlanName,
      currentPlan?.hasPaymentMethod,
    ]
  );

  const updatePlan = useCallback(
    async (paymentMethod?: string) => {
      const { error } = await updateRecruitingPlan({
        plan: selectedPlan,
        paymentMethod,
      });
      if (error) {
        createAlert("error", error);
      } else {
        // This promise resolves when the store changes to the new plan
        await waitForPlanUpdate();
        onSuccessfullUpdate();
      }
    },
    [createAlert, onSuccessfullUpdate, selectedPlan, waitForPlanUpdate]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={!!currentPlan}
      closeOnOverlayClick={!!currentPlan}
      title={
        currentPlanName
          ? "Upgrade your plan"
          : `Your ${
              isPricesLoaded
                ? `${pricesByPlan.Starter.trialPeriodDays}-day `
                : ""
            }free trial ended`
      }
      description="Identify top talent faster with our flexible plans."
      leftButtonLabel={step == "select" ? "Compare plans" : "Back"}
      leftButtonOnClick={() => {
        if (step === "select" && window)
          window.open(getExternalPricingUrl(), "_blank");
        if (step === "payment") setStep("select");
      }}
      leftButtonIconLeft={step == "select" ? LinkExternal02 : undefined}
      confirmLabel={step === "select" ? "Choose plan" : "Confirm payment"}
      onConfirm={async (event) => {
        event.preventDefault();
        if (step === "select") {
          if (!currentPlan?.hasPaymentMethod) {
            setStep("payment");
          } else {
            await updatePlan();
          }
        }
        if (step === "payment") {
          if (!stripe || !elements) return;
          const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            confirmParams: {
              return_url: getExternalPath(router.asPath),
            },
            redirect: "if_required",
          });

          if (error?.message) {
            createAlert("error", error.message);
          } else if (setupIntent && setupIntent.status === "succeeded") {
            await updatePlan(setupIntent.payment_method as string);
          }
        }
      }}
      isConfirmDisabled={
        // user may be on starter-trial (no payment method),
        // in which case we want to allow them to upgrade to starter
        (currentPlanName === selectedPlan && currentPlan?.hasPaymentMethod) ||
        !isPricesLoaded ||
        !isMembersLoaded ||
        tooManyMembers ||
        !stripe ||
        !elements
      }
      customCss={{ content: style.wrapper }}
    >
      {step === "select" && (
        <Dropdown
          onChange={(value) =>
            value && setSelectedPlan(value as SelectablePlan)
          }
          options={plans}
          label={"Choose a plan"}
          defaultValue={selectedPlan}
          placeholder={plans.length ? "Select a plan" : "Loading plans..."}
        />
      )}

      {step === "select" && selectedPlan && prices && (
        <>
          {tooManyMembers && (
            <Disclaimer
              type={"warning"}
              title={`Your team has ${
                members?.teamMembers.length
              } members, but this plan only allows for ${
                pricesByPlan[selectedPlan].teamSeats
              }. Please remove ${
                (members?.teamMembers?.length || 0) -
                pricesByPlan[selectedPlan].teamSeats
              } before proceeding.`}
            />
          )}
          <RecruitPlanDetail
            planName={selectedPlan}
            prices={prices}
            currentPlan={currentPlan}
          />
        </>
      )}
      {step === "payment" && (
        <div css={style.payment}>
          <PaymentElement />
        </div>
      )}
    </Modal>
  );
};
