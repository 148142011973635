import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

import { UiRole } from "apis/orgRoles";
import { ProfileColor } from "apis/posts";

export const uiGray = {
  10: "#F0F0F0",
  20: "#E1E2E2",
  30: "#C5C6C7",
  40: "#A9ABAD",
  50: "#8E9093",
  60: "#74777A",
  70: "#5B5E63",
  80: "#41474E",
  90: "#2C3036",
  100: "#191B1F",
  pageBackground: "#F5F6FA",
};

export const uiBlueGray = {
  50: "#F8F9FB",
  100: "#EBEDF4",
};

export const black = "#000000";

export const alphaBlack = {
  100: "rgba(0, 0, 0, 0.05)",
  200: "rgba(0, 0, 0, 0.1)",
  300: "rgba(0, 0, 0, 0.2)",
  400: "rgba(0, 0, 0, 0.3)",
  500: "rgba(0, 0, 0, 0.4)",
  600: "rgba(0, 0, 0, 0.5)",
  700: "rgba(0, 0, 0, 0.6)",
  800: "rgba(0, 0, 0, 0.7)",
  900: "rgba(0, 0, 0, 0.8)",
  1000: "rgba(0, 0, 0, 0.9)",
};

export const white = "#FFFFFF";

export const alphaWhite = {
  100: "rgba(255,255,255, 0.05)",
  200: "rgba(255,255,255, 0.1)",
  300: "rgba(255,255,255, 0.2)",
  400: "rgba(255,255,255, 0.3)",
  500: "rgba(255,255,255, 0.4)",
  600: "rgba(255,255,255, 0.5)",
  700: "rgba(255,255,255, 0.6)",
  800: "rgba(255,255,255, 0.7)",
  900: "rgba(255,255,255, 0.8)",
  1000: "rgba(255,255,255, 0.9)",
};

export const blue = {
  100: "#F3F6FF",
  200: "#E6EDFF",
  300: "#CBDAFF",
  400: "#AAC5FF",
  500: "#83ADFF",
  600: "#4791FF",
  700: "#4082E4",
  800: "#3770C6",
  900: "#2D5CA1",
  1000: "#204172",
};

export const purple = {
  50: "#F9F9FF",
  100: "#F3F2FF",
  200: "#E7E5FF",
  300: "#CCC8FF",
  400: "#ADA5FF",
  500: "#8779FF",
  600: "#512DFF",
  700: "#4828E4",
  800: "#3F23C6",
  900: "#331CA1",
  1000: "#241472",
  focusShadow: "#E6E4FF",
};

export const green = {
  100: "#F2FAF3",
  200: "#E4F4E7",
  300: "#C6E9CC",
  400: "#A1DCAD",
  500: "#72D087",
  600: "#04C250",
  700: "#04AE48",
  800: "#03963E",
  900: "#037B33",
  1000: "#025724",
};

export const pink = {
  100: "#FEF3F5",
  200: "#FDE6EA",
  300: "#FACAD4",
  400: "#F8AABB",
  500: "#F5819D",
  600: "#F34479",
  700: "#D93D6C",
  800: "#BC355E",
  900: "#9A2B4D",
  1000: "#6D1E36",
};

export const yellow = {
  50: "#FEFCE8",
  100: "#FEF8C3",
  200: "#FAF4E4",
  300: "#FEDF46",
  400: "#FBCB14",
  500: "#EBD375",
  600: "#CB8803",
  700: "#A26106",
  800: "#854C0E",
  900: "#713E12",
  1000: "#421F06",
};
export const red = {
  100: "#FCF3F3",
  200: "#F8E5E6",
  300: "#F1C9C9",
  400: "#EAA7A8",
  500: "#E37D7F",
  600: "#DB383E",
  700: "#C43237",
  800: "#AA2B30",
  900: "#8B2327",
  1000: "#62191C",
  focusError: "#FCEBEB",
};

export const gradient = {
  background:
    "linear-gradient(177.64deg, #E6E4FF -10.26%, rgba(255, 255, 255) 98.02%)",
  light:
    "linear-gradient(98.01deg, rgba(119, 102, 255, 0.3) 0%, rgba(81, 45, 255, 0.3) 50%, rgba(62, 34, 197, 0.3) 100%)",
  main: "linear-gradient(98.01deg, #7766FF 0%, #512DFF 50%, #3E22C5 100%)",
  dark: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(98.01deg, #7766FF 0%, #512DFF 50%, #3E22C5 100%)",
};

export const radialGradient: Record<ProfileColor | "grey", string> = {
  pink: "radial-gradient(42.64% 42.64% at 50% 104.41%, rgba(250, 100, 145, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
  green:
    "radial-gradient(42.64% 42.64% at 50% 104.41%, rgba(4, 194, 80, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
  purple:
    "radial-gradient(42.64% 42.64% at 50% 104.41%, rgba(81, 45, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
  blue: "radial-gradient(42.64% 42.64% at 50% 104.41%, rgba(71, 145, 255, 0.25) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
  yellow:
    "radial-gradient(42.64% 42.64% at 50% 104.41%, rgba(230, 199, 32, 0.25) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
  grey: "radial-gradient(42.64% 42.64% at 50% 104.41%, rgba(0,0,0, 0.25) 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF",
};

export const profileLabelColor: Record<ProfileColor | "grey", string> = {
  yellow: yellow[900],
  blue: blue[600],
  green: green[700],
  pink: pink[600],
  purple: purple[600],
  grey: black,
};

export const profileBackgroundColor: Record<ProfileColor, string> = {
  yellow: yellow[300],
  blue: blue[300],
  green: green[300],
  pink: pink[300],
  purple: purple[300],
};

// gradient.main
export const mainStrokeGradient: { id: string; def: () => EmotionJSX.Element } =
  {
    id: "stroke-gradient",
    def: () => (
      <defs>
        <linearGradient id="stroke-gradient" gradientTransform="rotate(8.01)">
          <stop offset="0%" stopColor="#7766FF" />
          <stop offset="50%" stopColor={purple[600]} />
          <stop offset="100%" stopColor="#3E22C5" />
        </linearGradient>
      </defs>
    ),
  };

// gradient.light,
export const lightStrokeGradient: {
  id: string;
  def: () => EmotionJSX.Element;
} = {
  id: "disabled-stroke-gradient",
  def: () => (
    <defs>
      <linearGradient
        id="disabled-stroke-gradient"
        gradientTransform="rotate(8.01)"
      >
        <stop offset="0%" stopColor="rgba(119, 102, 255, 0.3)" />
        <stop offset="50%" stopColor="rgba(81, 45, 255, 0.3)" />
        <stop offset="100%" stopColor="rgba(62, 34, 197, 0.3)" />
      </linearGradient>
    </defs>
  ),
};

export const confettiColors = [
  "#72CF86",
  "#8778FF",
  "#F5819D",
  "#82ACFF",
  "#E6C720",
];

export const UiRoleToColorMap: Record<UiRole, "purple" | "green" | "red"> = {
  "Super Admin": "purple",
  Issuer: "green",
  Employee: "red",
  Pending: "red",
};

export const ribbonProAccentColorPlanMap = {
  purple: purple[600],
  grey: uiGray[90],
};
