import { AlertCircleIcon } from "Icons/AlertCircle";
import { AlertTriangleIcon } from "Icons/AlertTriangle";
import { InfoCircleIcon } from "Icons/InfoCircle";
import { XCloseIcon } from "Icons/XClose";

import { ReactNode, useState } from "react";

import { SerializedStyles, css } from "@emotion/react";

import { Button, Props as ButtonProps } from "components/shared/library/Button";
import { ButtonWrapper } from "components/shared/library/ButtonWrapper";
import { Typography } from "components/shared/library/Typography";

import { down } from "utils/mediaQueryStrings";

import { purple, red, yellow } from "constants/colors";

const style = {
  disclaimerColor: {
    info: css({
      background: purple[100],
      border: `1px solid ${purple[600]}`,
    }),
    warning: css({
      background: yellow[200],
      border: `1px solid ${yellow[500]}`,
    }),
    error: css({
      background: red[100],
      border: `1px solid ${red[700]}`,
    }),
  },
  disclaimer: css({
    borderRadius: 12,
    display: "flex",
    padding: 16,
    gap: 12,
    alignItems: "flex-start",
  }),
  icon: css({
    padding: 2,
  }),
  content: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
    flex: 1,
  }),
  text: css({
    display: "flex",
    flexDirection: "column",
    gap: 4,
  }),
  buttons: css({
    display: "flex",
    gap: 4,
    [down("mobile")]: {
      flexDirection: "column",
    },
  }),
};
type DisclaimerType = "info" | "warning" | "error";
const iconMap = {
  warning: AlertTriangleIcon,
  info: InfoCircleIcon,
  error: AlertCircleIcon,
};
const buttonColorMap: Record<DisclaimerType, ButtonProps["color"]> = {
  warning: "yellow",
  info: "purple",
  error: "red",
};
const textColorMap = {
  warning: yellow[900],
  info: purple[600],
  error: red[700],
};

export const Disclaimer = ({
  type,
  title,
  subtitle,
  isDismissible,
  primaryButtonProps,
  secondaryButtonProps,
  showDismissButton = false,
  dismissLabel = "Dismiss",
  customCss,
  children,
}: {
  type: DisclaimerType;
  title: string;
  subtitle?: string | ReactNode;
  isDismissible?: boolean;
  primaryButtonProps?: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  showDismissButton?: boolean;
  dismissLabel?: string;
  customCss?: SerializedStyles;
  children?: ReactNode;
}) => {
  const [isDismissed, setisDismissed] = useState(false);
  const Icon = iconMap[type];

  if (isDismissed) return <></>;
  return (
    <div css={[style.disclaimer, style.disclaimerColor[type], customCss]}>
      <div css={style.icon}>
        <Icon stroke={textColorMap[type]} width={16} height={16} />{" "}
      </div>
      <div css={style.content}>
        <div css={style.text}>
          <Typography color={textColorMap[type]} weight="medium">
            {title}
          </Typography>
          {subtitle && (
            <Typography color={textColorMap[type]}>{subtitle}</Typography>
          )}
        </div>
        {(showDismissButton || primaryButtonProps || secondaryButtonProps) && (
          <div css={style.buttons}>
            {primaryButtonProps && (
              <Button
                size="small"
                color={buttonColorMap[type]}
                {...primaryButtonProps}
              />
            )}
            {secondaryButtonProps && (
              <Button
                size="small"
                color={buttonColorMap[type]}
                {...secondaryButtonProps}
              />
            )}
            {showDismissButton && (
              <Button
                size="small"
                background="outlined"
                color={buttonColorMap[type]}
                onClick={() => setisDismissed(true)}
              >
                {dismissLabel}
              </Button>
            )}
          </div>
        )}
        {children}
      </div>
      {isDismissible && (
        <ButtonWrapper ariaLabel="Close" onClick={() => setisDismissed(true)}>
          <XCloseIcon height={20} width={20} stroke={textColorMap[type]} />
        </ButtonWrapper>
      )}
    </div>
  );
};
