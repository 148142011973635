import { isAfter } from "date-fns";
import { jsPDF } from "jspdf";

import { useCallback, useEffect, useMemo, useRef } from "react";

import { CandidateStatus, InterviewCall, Transcript } from "apis/recruit";
import { RecruitPlanName, RecruitPrice } from "apis/recruitingTeam";

import { getUserFullName } from "./accountProfile";
import { formatAmountToDollars } from "./earnings";
import { isTruthy } from "./typescript";

export type Sorter =
  | "Most recent"
  | "Alphabetically"
  | "By Status"
  | "By Score";

export const filterCandidates = ({
  filters,
  calls,
  searchFilter,
  status,
  selectedScores,
}: {
  filters?: Record<string, string>;
  calls?: InterviewCall[];
  searchFilter?: string;
  status?: CandidateStatus | null;
  selectedScores?: number[];
}): InterviewCall[] => {
  return (
    calls?.filter(
      (call) =>
        filterBySearchValue(call, searchFilter) &&
        filterByFilters(call, filters) &&
        filterByStatus(call, status) &&
        filterByScore(call, selectedScores)
    ) || []
  );
};

export const filterByFilters = (
  call: InterviewCall,
  filters?: Record<string, string>
): boolean => {
  return Object.entries(filters || {}).every(([questionId, answer]) =>
    call.filters.find(
      (filter) => filter.questionId === questionId && filter.answer === answer
    )
  );
};

export const filterByStatus = (
  call: InterviewCall,
  status?: CandidateStatus | null
): boolean => {
  return !status || call.candidateStatus === status;
};

export const filterBySearchValue = (
  call: InterviewCall,
  searchFilter?: string
): boolean => {
  return (
    !searchFilter || // If searchFilter is not provided or empty
    getUserFullName(call.candidate)
      .toLowerCase()
      .includes(searchFilter.toLowerCase())
  );
};

export const filterByScore = (
  call: InterviewCall,
  selectedStarRatings?: number[]
): boolean => {
  return (
    !selectedStarRatings ||
    selectedStarRatings.length === 0 ||
    selectedStarRatings.includes(Math.round(call.overallScore || 0))
  );
};

export const sortCandidates = (sorter: Sorter, calls?: InterviewCall[]) =>
  calls?.sort((A, B) => {
    switch (sorter) {
      case "Alphabetically": {
        const fullName = (x: InterviewCall) =>
          `${x.candidate.firstName} ${x.candidate.lastName}`.toLowerCase();
        return fullName(A) < fullName(B) ? -1 : 1;
      }
      case "By Score":
        return (A.overallScore || 0) > (B.overallScore || 0) ? -1 : 1;
      case "By Status": {
        const order = { "pending-review": 0, accepted: 1, rejected: 2 };
        return order[A.candidateStatus] < order[B.candidateStatus] ? -1 : 1;
      }
      case "Most recent":
        return isAfter(new Date(A.endedAt), new Date(B.endedAt)) ? -1 : 1;
    }
  }) || [];

export const downloadTranscript = (
  transcript: string,
  candidateName: string
) => {
  const doc = new jsPDF();
  const margin = 10;
  const pageHeight = doc.internal.pageSize.height;
  const lineHeight = 10;
  const title = `Interview transcript for ${candidateName}`;
  const titleFontSize = 16;
  const textFontSize = 12;
  const maxLinesPerPage = Math.floor(
    (pageHeight - margin * 2 - titleFontSize - lineHeight) / lineHeight
  );

  // Set the font size for the title
  doc.setFontSize(titleFontSize);
  doc.text(title, margin, margin + titleFontSize / 2);

  // Set the font size for the text
  doc.setFontSize(textFontSize);

  const lines = doc.splitTextToSize(
    transcript
      .replaceAll("User:", `${candidateName}:  `) // replace User to the candidates name
      .replaceAll("Agent:", "Recruit AI:  "), // replace Agent to say Recruit AI name
    doc.internal.pageSize.width - margin * 2
  );
  let currentLine = 0;

  while (currentLine < lines.length) {
    if (currentLine > 0) {
      doc.addPage();
    }
    const startY =
      currentLine === 0 ? margin + titleFontSize + lineHeight : margin;
    for (let i = 0; i < maxLinesPerPage && currentLine < lines.length; i++) {
      const line = lines[currentLine];
      const userText = `${candidateName}:  `;
      const agentText = "Recruit AI:  ";
      let parts;
      if (line.includes(userText)) {
        // bold the candidate's name
        parts = line.split(userText);
        doc.text(parts[0], margin, startY + i * lineHeight);
        doc.setFont("helvetica", "bold");
        doc.text(
          userText,
          margin + doc.getTextWidth(parts[0]),
          startY + i * lineHeight
        );
        doc.setFont("helvetica", "normal");
        doc.text(
          parts[1],
          margin + doc.getTextWidth(parts[0] + userText),
          startY + i * lineHeight
        );
      } else if (line.includes(agentText)) {
        // bold the recruit AI name
        parts = line.replace("Agent:", agentText).split(agentText);
        doc.text(parts[0], margin, startY + i * lineHeight);
        doc.setFont("helvetica", "bold");
        doc.text(
          agentText,
          margin + doc.getTextWidth(parts[0]),
          startY + i * lineHeight
        );
        doc.setFont("helvetica", "normal");
        doc.text(
          parts[1],
          margin + doc.getTextWidth(parts[0] + agentText),
          startY + i * lineHeight
        );
      } else {
        doc.text(line, margin, startY + i * lineHeight);
      }

      currentLine++;
    }
  }

  doc.save("transcript.pdf");
};

// returns the previous and next segment to skip to
export const useSegmentSkipNav = (
  currentTime: number,
  transcriptWithTimeStamp?: Transcript
) => {
  // an array of each segment start time
  const segmentStarts = useMemo(
    () =>
      transcriptWithTimeStamp?.map(
        (segment) => Math.floor(segment.words[0].start * 1000000) / 1000000
      ),
    [transcriptWithTimeStamp]
  );

  // the current segment start is the first one where next is (1s) bigger than the current time, or if none found, the last segment
  const prevSegment = useMemo(
    () =>
      segmentStarts?.find(
        (_, i, starts) =>
          starts[i + 1] + 1 > currentTime || i === starts.length - 1
      ),
    [currentTime, segmentStarts]
  );

  // the next segment start is the first one strictly bigger than the current time
  const nextSegment = useMemo(
    () => segmentStarts?.find((segmentStart) => segmentStart > currentTime),
    [currentTime, segmentStarts]
  );

  return { prevSegment, nextSegment };
};

export const getRecruitPriceFeatures = (
  selectedPlan: RecruitPrice,
  lowerPlan: RecruitPrice | null
) => {
  return [
    `${
      selectedPlan.includedInterviewsPerRecurringInterval
    } interviews included every ${
      selectedPlan.usageBasedRecurringInterval
    } (After, ${formatAmountToDollars(
      selectedPlan.usageBasedUnitAmountDecimal
    )} per interview)`,
    `${selectedPlan.teamSeats} seats included`,
    !lowerPlan && "Create unlimited interview flows",
    !lowerPlan &&
      "Advanced analysis of interviews (summary, scoring, and more)",
    selectedPlan.hasTalentPoolAccess &&
      !lowerPlan?.hasTalentPoolAccess &&
      "Access to 1000s of high quality candidates in Talent Hub",
    selectedPlan.hasVideoInterviewsAccess &&
      !lowerPlan?.hasVideoInterviewsAccess &&
      "Interview candidates with video",
    selectedPlan.voicesIncluded.length > 1 &&
      (lowerPlan?.voicesIncluded.length || 0) <= 1 &&
      "Change interview voice and language",
    selectedPlan.hasWhiteLabelAccess &&
      !lowerPlan?.hasWhiteLabelAccess &&
      "Custom white-label interview look",
    selectedPlan.hasIntegrationsAccess &&
      !lowerPlan?.hasIntegrationsAccess &&
      "ATS integration",
  ].filter(isTruthy);
};

export const useWaitForPlanUpdate = ({
  currentPlanName,
  selectedPlan,
}: {
  selectedPlan?: RecruitPlanName;
  currentPlanName?: RecruitPlanName;
}) => {
  // we create a ref with the currentPlanName
  // so that it can be accessed "live" (not its value at the time it's called)
  // in waitForPlanUpdate timeout
  const currentPlanNameRef = useRef(currentPlanName);
  useEffect(() => {
    currentPlanNameRef.current = currentPlanName;
  }, [currentPlanName]);

  // This promise resolves as soon as the store updated to match the selected value
  const waitForPlanUpdate = useCallback(async () => {
    return new Promise<void>((resolve) => {
      const checkPlan = () => {
        if (currentPlanNameRef.current === selectedPlan) {
          resolve();
        } else {
          setTimeout(checkPlan, 100); // Check every 100ms
        }
      };
      checkPlan();
    });
  }, [selectedPlan]);

  return waitForPlanUpdate;
};
