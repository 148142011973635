import { useEffect } from "react";

import { useRouter } from "next/router";

import { AuthGatedProvider } from "contexts/AuthGatedContext";
import { useAuth } from "contexts/authContext";

import { signOut } from "apis/authentication";

import { getSignInUrl } from "utils/urls";

type Props = {
  children: React.ReactNode;
  redirectToSignIn?: boolean;
  redirectTo?: string;
  onSuccessfulAuthRedirect?: string;
  allowAnonymousUsers?: boolean;
};

export const Authenticated = ({
  children,
  redirectToSignIn = false,
  redirectTo,
  onSuccessfulAuthRedirect,
  allowAnonymousUsers = false,
}: Props) => {
  const { user, isLoaded } = useAuth();
  const router = useRouter();
  const userIsAllowedThrough =
    user && (allowAnonymousUsers || !user.is_anonymous);

  useEffect(() => {
    // redirect is called only if redirectTo or redirectToSignIn is true
    // and if the user is not allowed to go through
    const redirect = async () => {
      if (user?.is_anonymous) {
        //anonymous users need are signed out before being redirected
        await signOut();
      }
      router.push(
        redirectTo
          ? redirectTo
          : getSignInUrl({
              currentPath: router.asPath,
              onSuccessfulAuthRedirect,
            })
      );
    };
    if (
      isLoaded &&
      router.isReady &&
      !userIsAllowedThrough &&
      (redirectTo || redirectToSignIn)
    )
      redirect();
  }, [
    user,
    isLoaded,
    onSuccessfulAuthRedirect,
    redirectTo,
    redirectToSignIn,
    router,
    userIsAllowedThrough,
  ]);

  return userIsAllowedThrough ? (
    <AuthGatedProvider>{children}</AuthGatedProvider>
  ) : null;
};
