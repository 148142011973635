// use in timer that give a UI indication to a user before disappearing
// also used in timer to wait until logging profile view
export const standardWaitTime = 2000;

// use in timer where we want to wait for a state update
// this wait time should feel almost instant
export const stateUpdateWaitTime = 400;

// use in timer after which we should log an error
export const errorLogTime = 10000;

export const realtimeHealthCheckInterval = 10000;

export const signUpResendWaitTime = 60000;

export const confettiDuration = 50000;
