import { useEffect, useMemo, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useRouter } from "next/router";

import { SelectPlanModal } from "components/recruit/SelectPlanModal";
import { Button, Props as ButtonProps } from "components/shared/library/Button";

import { getClientSecret } from "apis/recruit";
import {
  RecruitPlan,
  RecruitPlanName,
  useRecruiterTeam,
} from "apis/recruitingTeam";

type SelectablePlan = Exclude<RecruitPlanName, "Enterprise">;

type Props = Omit<ButtonProps, "onClick"> & {
  defaultPlanName?: SelectablePlan;
  currentPlan: RecruitPlan | null;
  showButton?: boolean;
  onClose?: () => void;
};

export const UpgradePlanButton = ({
  defaultPlanName,
  currentPlan,
  showButton = true,
  onClose,
  ...buttonProps
}: Props) => {
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(!showButton);

  const [clientSecret, setClientSecret] = useState<string>();

  const stripePromise = useMemo(
    () => loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY || ""),
    []
  );

  const { team } = useRecruiterTeam();
  const router = useRouter();

  useEffect(() => {
    const get = async () => {
      if (!team?.isOwner) return;
      const secret = await getClientSecret();
      setClientSecret(secret);
    };
    get();
  }, [team?.isOwner]);

  // there is no use case where "Enterprise" users should see this button / flow
  if (currentPlan?.planName === "Enterprise") return <></>;

  // only team owners can change plans
  if (!team?.isOwner) return <></>;

  return (
    <>
      {showButton && (
        <Button
          {...buttonProps}
          onClick={() => setIsSelectModalOpen(true)}
          disabled={!stripePromise || !clientSecret}
        />
      )}
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <SelectPlanModal
            isOpen={isSelectModalOpen}
            onClose={() => {
              setIsSelectModalOpen(false);
              if (onClose) onClose();
            }}
            onSuccessfullUpdate={() => {
              setIsSelectModalOpen(false);
              router.query = { showPlanSuccessModal: "true" };
              router.push(router);
            }}
            defaultPlanName={defaultPlanName}
            currentPlan={currentPlan}
          />
        </Elements>
      )}
    </>
  );
};
