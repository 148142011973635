import { IconProps } from "Icons/types";

import { uiGray } from "constants/colors";

export const HeartSmileyIcon = ({
  width = 24,
  height = 24,
  stroke = uiGray[80],
  strokeGradient,
  strokeGradientId,
}: IconProps) => (
  <svg
    data-testid="heart-smiley"
    className="heart-smiley-icon"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3152 6.22438L12 7.74927L10.6848 6.22438C10.0607 5.50083 9.04922 4.73684 7.47778 4.73684C4.81633 4.73684 2.73684 7.3263 2.73684 10.04C2.73684 11.7619 3.69173 13.5643 5.26649 15.3204C6.81534 17.0476 8.77426 18.5171 10.3407 19.5467C11.3595 20.2163 12.6405 20.2163 13.6593 19.5467C15.2257 18.5171 17.1847 17.0476 18.7335 15.3204C20.3083 13.5643 21.2632 11.7619 21.2632 10.04C21.2632 7.3263 19.1837 4.73684 16.5222 4.73684C14.9508 4.73684 13.9393 5.50083 13.3152 6.22438ZM16.5222 3C15.1803 3 14.1186 3.40477 13.3022 3.9406C12.7654 4.293 12.3346 4.70208 12 5.09C11.6654 4.70208 11.2346 4.293 10.6978 3.9406C9.8814 3.40477 8.8197 3 7.47778 3C3.60333 3 1 6.64222 1 10.04C1 14.7408 6.13354 18.8598 9.38673 20.9981C10.9845 22.0483 13.0155 22.0483 14.6133 20.9981C17.8665 18.8598 23 14.7408 23 10.04C23 6.64222 20.3967 3 16.5222 3ZM13.1606 10.6294C13.1606 11.1984 13.6218 11.6596 14.1908 11.6596C14.7598 11.6596 15.2211 11.1984 15.2211 10.6294C15.2211 10.0604 14.7598 9.5991 14.1908 9.5991C13.6218 9.5991 13.1606 10.0604 13.1606 10.6294ZM9.59424 11.6596C9.02525 11.6596 8.56398 11.1984 8.56398 10.6294C8.56398 10.0604 9.02525 9.5991 9.59424 9.5991C10.1632 9.5991 10.6245 10.0604 10.6245 10.6294C10.6245 11.1984 10.1632 11.6596 9.59424 11.6596ZM13.9235 13.1429C14.14 12.7829 14.6177 12.6605 14.9906 12.8694C15.3635 13.0784 15.4903 13.5395 15.2739 13.8995C14.9301 14.4712 14.4368 14.9457 13.8434 15.2756C13.25 15.6054 12.5772 15.779 11.8924 15.779C11.2077 15.779 10.5349 15.6054 9.94148 15.2756C9.34805 14.9457 8.85478 14.4712 8.51102 13.8995C8.29458 13.5395 8.42141 13.0784 8.79429 12.8694C9.16717 12.6605 9.6449 12.7829 9.86134 13.1429C10.0678 13.4863 10.3641 13.7713 10.7206 13.9694C11.077 14.1676 11.4811 14.2719 11.8924 14.2719C12.3038 14.2719 12.7079 14.1676 13.0643 13.9694C13.4208 13.7713 13.7171 13.4863 13.9235 13.1429Z"
      fill={
        strokeGradientId && strokeGradient
          ? `url(#${strokeGradientId})`
          : stroke
      }
    />
    {strokeGradient && strokeGradientId && strokeGradient()}
  </svg>
);
