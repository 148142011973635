import { LoadingIcon } from "Icons/Loading";
import { IconType } from "Icons/types";

import { RefObject, useMemo, useState } from "react";

import { SerializedStyles, css } from "@emotion/react";

import { trackButton } from "interfaces/tracker";

import { useButtonLoading } from "contexts/ButtonLoadingContext";

import { getInnerText } from "utils/text";

import { ROTATE_ICON_ANIMATION } from "constants/animations";
import {
  buttonColorMatrix,
  ButtonMatrix,
  buttonSizes as sizes,
} from "constants/button";
import {
  lightStrokeGradient,
  mainStrokeGradient,
  white,
} from "constants/colors";

export type Background = "filled" | "ghost" | "outlined" | "link";
export type Color = "purple" | "black" | "red" | "gray" | "yellow";
export type Size = "large" | "medium" | "small" | "extra-small" | "XXS";

const getStrokeGradient = (
  background: Background,
  color: Color,
  disabled: boolean
) => {
  if (background === "filled" || color != "purple") return undefined;
  return disabled ? lightStrokeGradient : mainStrokeGradient;
};

const getIcon = (
  buttonMatrix: ButtonMatrix,
  Icon: IconType,
  background: Background,
  color: Color,
  size: Size,
  disabled: boolean
) => {
  const state = disabled ? "disabled" : "default";
  return (
    <Icon
      stroke={buttonMatrix[background][state]["text"]}
      strokeGradient={getStrokeGradient(background, color, disabled)?.def}
      strokeGradientId={getStrokeGradient(background, color, disabled)?.id}
      strokeWidth={sizes[size].iconStrokeWidth}
      width={sizes[size].iconSize}
      height={sizes[size].iconSize}
    />
  );
};

const getStyle = (
  buttonMatrix: ButtonMatrix,
  color: Color,
  background: Background,
  size: Size,
  isCircle: boolean,
  isSquare: boolean,
  disabled: boolean,
  isFullWidth: boolean
) => {
  const colors = buttonMatrix[background];
  const maxWidth = isFullWidth ? "100%" : "fit-content";
  const state = disabled ? "disabled" : "default";
  const hoverState = disabled ? "disabled" : "hover";
  // for purple outlined and ghost button, we use clips to have the text appear with the gradient
  // that make the logic more complicated, isClipped is a helper var to check that case
  const isClipped = color === "purple" && background != "filled";
  // purple border has a gradient & cannot be done with 'border'. Therefore requires an additional element that add 1px all around the element.
  // This calc rectifies this.
  const clippedPadding = isClipped ? 1 : 0;
  const borderRadius = isSquare ? 4 : 100;
  return {
    rotate: css({
      display: "flex",
      animation: ROTATE_ICON_ANIMATION,
    }),
    centerIcon: css({
      position: "absolute",
      left: 0,
      right: 0,
      display: "flex",
      justifyContent: "center",
    }),
    border: css({
      maxWidth: "100%",
      backgroundImage: isClipped ? colors[state]["border"] : "none",
      padding: clippedPadding,
      borderRadius: borderRadius,
      width: maxWidth,
      "&:focus-within": {
        boxShadow: `0px 0px 0px 4px ${colors["focus"]["outline"]}`,
      },
    }),
    clip: css({
      height: "100%",
      background: isClipped ? white : "transparent",
      borderRadius: borderRadius,
      "&:hover": {
        background: isClipped
          ? colors[hoverState]["background"]
          : "transparent",
        textDecoration:
          background === "link" && !disabled ? "underline" : "none",
      },
      "&:focus-within": {
        textDecoration:
          background === "link" && !disabled ? "underline" : "none",
      },
    }),
    button: css({
      maxWidth: "100%",
      fontStyle: "normal",
      fontWeight: sizes[size].fontWeight,
      fontSize: sizes[size].fontSize,
      color: colors[state]["text"],
      cursor: disabled ? "default" : "pointer",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      paddingTop:
        isCircle || background === "link" ? 0 : sizes[size].paddingVertical,
      paddingBottom:
        isCircle || background === "link" ? 0 : sizes[size].paddingVertical,
      paddingLeft:
        isCircle || background === "link"
          ? 0
          : sizes[size].paddingHorizontal - clippedPadding,
      paddingRight:
        isCircle || background === "link"
          ? 0
          : sizes[size].paddingHorizontal - clippedPadding,
      width: isCircle ? sizes[size].height : maxWidth,
      height: sizes[size].height - 2 * clippedPadding,
      border:
        background === "outlined" && color != "purple"
          ? `1px solid ${colors[state]["border"]}`
          : "none",
      borderRadius: background === "link" ? 0 : borderRadius,
      background: isClipped
        ? colors[state]["text"]
        : colors[state]["background"],
      WebkitBackgroundClip: isClipped ? "text" : "none",
      WebkitTextFillColor: isClipped ? "transparent" : "none",
      position: "relative",
      ...(isClipped
        ? {
            "&:focus": {
              outline: "none",
            },
          }
        : {
            "&:hover": {
              background: colors[hoverState]["background"],
              color: colors[hoverState]["text"],
              textDecoration:
                background === "link" && !disabled ? "underline" : "none",
            },
            "&:focus": {
              outline: "none",
              background: colors["focus"]["background"],
              textDecoration:
                background === "link" && !disabled ? "underline" : "none",
            },
          }),
    }),
    content: css({
      padding: 0,
      height: "inherit",
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      maxWidth: "100%",
    }),
    iconLeft: css({
      paddingRight: isCircle ? 0 : sizes[size].textPadding,
      display: "flex",
    }),
    iconRight: css({
      paddingLeft: isCircle ? 0 : sizes[size].textPadding,
      display: "flex",
    }),
  };
};

export type Props = {
  id?: string;
  children?: React.ReactNode;
  onMouseDown?: () => void;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<boolean | void> | void; // if onClick is a Promise, then it should return true if onClickSuccess should be executed, and false, if onClickFail should be executed but if onClick returns void, neither onClickSuccess or onClickFail will be executed
  onClickSuccess?: () => void;
  onClickFail?: () => void;
  size?: Size;
  background?: Background;
  IconLeft?: IconType;
  IconRight?: IconType;
  disabled?: boolean;
  ariaLabel?: string;
  isFullWidth?: boolean;
  customCss?: {
    button?: SerializedStyles;
    iconLeft?: SerializedStyles;
    wrapper?: SerializedStyles | SerializedStyles[];
    clip?: SerializedStyles;
  };
  buttonRef?: RefObject<HTMLButtonElement> | null;
  testId?: string;
  isSquare?: boolean;
  isLoading?: boolean;
  color?: Color;
  customColorMatrix?: ButtonMatrix; // when provided this overrrides color prop
};

export const Button = ({
  id,
  children,
  onClick,
  onClickSuccess,
  onClickFail,
  size = "large",
  color = "purple",
  background = "filled",
  disabled = false,
  IconLeft,
  IconRight,
  ariaLabel,
  isFullWidth = false,
  customCss,
  buttonRef = undefined,
  testId = undefined,
  onMouseDown,
  isSquare = false,
  isLoading: overrideIsLoading,
  customColorMatrix,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setIsLoading: setButtonLoading } = useButtonLoading();

  const showAsLoading = useMemo(
    () => overrideIsLoading || isLoading,
    [isLoading, overrideIsLoading]
  );

  const isDisabled = disabled || showAsLoading;
  const isCircle = !children;
  const colorMatrix = customColorMatrix || buttonColorMatrix[color];
  const style = getStyle(
    colorMatrix,
    color,
    background,
    size,
    isCircle,
    isSquare,
    isDisabled,
    isFullWidth
  );

  return (
    <div css={[style.border, customCss?.wrapper]} id={id}>
      <div css={[style.clip, customCss?.clip]}>
        <button
          aria-label={ariaLabel}
          css={[style.button, customCss?.button]}
          disabled={isDisabled}
          ref={buttonRef}
          data-testid={testId}
          onMouseDown={() => {
            if (!onMouseDown) return;
            onMouseDown();
          }}
          onClick={async (e) => {
            if (!onClick) return;
            setIsLoading(true);
            setButtonLoading(true); // updates the loading state of the button context
            const result = onClick(e);
            if (result instanceof Promise) {
              // if onClick is a Promise we await it to see if it is successful
              const isSuccess = await result;
              if (isSuccess && onClickSuccess) onClickSuccess();
              // only execute onClickFail if isSuccess is a boolean false value
              if (typeof isSuccess === "boolean" && !isSuccess && onClickFail)
                onClickFail();
              trackButton(
                ariaLabel || getInnerText(children),
                window.location.pathname,
                isSuccess
              );
            } else {
              trackButton(
                ariaLabel || getInnerText(children),
                window.location.pathname
              );
            }

            setIsLoading(false);
            setButtonLoading(false);
          }}
        >
          {IconLeft && (
            <div css={[style.iconLeft, customCss?.iconLeft]}>
              {showAsLoading ? (
                <div css={style.rotate}>
                  {getIcon(
                    colorMatrix,
                    LoadingIcon,
                    background,
                    color,
                    size,
                    isDisabled
                  )}
                </div>
              ) : (
                getIcon(
                  colorMatrix,
                  IconLeft,
                  background,
                  color,
                  size,
                  isDisabled
                )
              )}
            </div>
          )}
          <div css={style.content}>
            <div
              css={css({
                opacity: !IconLeft && !IconRight && showAsLoading ? 0 : 1,
                maxWidth: "100%",
              })}
            >
              {children}
            </div>
            {!IconLeft && !IconRight && showAsLoading && (
              <div css={style.centerIcon}>
                <div css={style.rotate}>
                  {getIcon(
                    colorMatrix,
                    LoadingIcon,
                    background,
                    color,
                    size,
                    isDisabled
                  )}
                </div>
              </div>
            )}
          </div>
          {IconRight && (
            <div css={style.iconRight}>
              {showAsLoading ? (
                <div css={style.rotate}>
                  {getIcon(
                    colorMatrix,
                    LoadingIcon,
                    background,
                    color,
                    size,
                    isDisabled
                  )}
                </div>
              ) : (
                getIcon(
                  colorMatrix,
                  IconRight,
                  background,
                  color,
                  size,
                  isDisabled
                )
              )}
            </div>
          )}
        </button>
      </div>
    </div>
  );
};
